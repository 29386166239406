export default {
  ActivityOverview: 'Activity overview',
  AddToStoreNavbar: 'Add to store navbar',
  ComingSoon: 'Coming soon',
  ConfigureLiveShoppingPageModal: {
    Step1: '1. In the Content Tab, click on Menus and then select Main Menu',
    Step2: '2. Add Menu Item (page)',
    Step3: '3. Label the new page and select Live Shopping in the list',
    Step4: '4. Clic on Add and finally Save',
    Title: 'Display the Live Shopping Page on your Shopify',
  },
  ConfigureMenu: 'Configure my Menu',
  CopyLinkToEvent: 'Copy link to Event',
  CreateLive: {
    Title: 'Go live!',
  },
  CreateLiveAction: 'Create Live Event',
  CreateLiveShoppingPage: 'Create Live Shopping Page',
  CreateShoppableAction: 'Create shoppable',
  DisplayOnShopify: 'Display on Shopify',
  EventSettings: 'Event settings',
  GetStarted: {
    LiveShopping: {
      Cards: {
        AddLiveShoppingPage: {
          ActionButton: 'Add to store navbar',
          Description: 'This page will host Live events and their replay clips',
          Duration: 'No code / 1 min install',
          Title: 'Display Live Shopping Page on your store',
        },
      },
      Title: 'Get started with Live Shopping',
    },
    Shoppables: {
      Cards: {
        AddCarousel: {
          ActionButton: 'Add block',
          Description: 'Select our Widget Stories or our Widget Carousel and enrich your store with shoppable videos',
          Duration: 'No code / 1 min install',
          Title: 'Add Shoppable Video widget on your store',
        },
      },
      Title: 'Get started with Shoppables',
    },
  },
  Hello: 'Hello!',
  LiveEvents: 'Live events',
  NoPlannedEvent: 'No event is planned',
  Notifications: {
    ShoppableWidgetNotYetActive: 'Your shoppable widget is not yet active.',
    ShoppingPageNotFound: 'The Live Shopping Page was not found on your Shopify.',
  },
  Plan: {
    SeeAllPlans: 'See all plans',
    YourPlan: 'You’re on {{planName}} plan.',
  },
  ProductPagesWithMediaCollectionsCount: '{{count}}/{{maxCount}} product pages with videos',
  ProductPagesWithMediaCollectionsCountTitle: 'Videos everywhere?',
  ReleaseNotes: {
    Title: 'New Features :',
    'v0.15':
      'We have (again) some new things for you!\n<Category>Live Now widget:</Category> Add our nice widget on your store so that any visitor is aware that you are selling live now.\n<Category>More Analytics:</Category> Track the success of your Shoppable videos in the dedicated section <ShoppableVideoAnalyticsLink>here</ShoppableVideoAnalyticsLink>.\n<Category>Restream anywhere:</Category> Go beyond IG, FB and YT and stream onto any platform where you have a “Creator” account.',
    'v0.15-NonShopify':
      'We have (again) some new things for you!\n<Category>Restream anywhere:</Category> Go beyond IG, FB and YT and stream onto any platform where you have a "Creator" account.',
    'v0.17':
      "We have (again) some new things for you!\n<Category>Widget Carousel:</Category>  A new block to add on your store! Display shoppable videos in poster format on your Shopify to boost traffic and sales.\n<Category>Shoppable Playlist swipe:</Category>  As on TikTok or IG, your clients can swipe through all your videos ... and still buy your products. So don't be shy and <OpenShoppablesLink>add more shoppable videos</OpenShoppablesLink> to your home, product and collection pages.",
    'v0.17-NonShopify':
      'We have (again) some new things for you!\n<Category>Restream anywhere:</Category> Go beyond IG, FB and YT and stream onto any platform where you have a "Creator" account.',
    'v0.18':
      'We have (again) some new things for you!\n<Category>Highlight Multiple Upcoming Events:</Category>  You can now display more than 1 event (on paid plans only) on your Shopify!\n<Category>FOMO:</Category>  Both Moderator and participants have info about remaining stock in your Live Event. Use the power of FOMO to boost your sales.',
    'v0.18-NonShopify':
      'We have (again) some new things for you!\n<Category>Restream anywhere:</Category> Go beyond IG, FB and YT and stream onto any platform where you have a "Creator" account.',
    'v0.19':
      'We have (again) some new things for you!\n<Category>Introducing Discovery Feed:</Category>  Link your playlists together for a seamless, continuous stream of shoppable videos across products! Let your clients Swipe and Shop on your Shopify! Available with your Grow, Scale and Entreprise plans.',
    'v0.19-NonShopify':
      'We have (again) some new things for you!\n<Category>Restream anywhere:</Category> Go beyond IG, FB and YT and stream onto any platform where you have a "Creator" account.',
  },
  Replays: 'Replays',
  ShoppableVideos: 'Shoppable videos',
  Shoppables: 'Shoppables',
  ShoppablesCount: '{{count}} displayed videos',
  ShoppablesCountWithMaxCount: '{{count}}/{{maxCount}} displayed videos',
  TotalAmountInCheckout: 'Total in checkout',
  TotalOrders: 'Total orders',
  TotalSales: 'Total sales',
  TotalViews: 'Total views',
  Tutorial: 'Tutorial',
  UpcomingLive: 'Upcoming live',
  UpgradeForMore: 'Upgrade for more',
  ValidatedCarts: 'Validated carts',
};
