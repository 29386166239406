import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CBOCatalogProduct } from '../../../../../core/domain/CBOCatalogProduct';
import { CBOMediaCollection } from '../../../../../core/domain/CBOMediaCollection';
import {
  CBOMediaCollectionMediaShoppable,
  isCBOMediaCollectionMediaShoppable,
} from '../../../../../core/domain/CBOMediaCollectionMedia';
import { isCBOMediaCollectionPublishedPageProduct } from '../../../../../core/domain/CBOMediaCollectionPublishedPage';
import {
  CBOShopifyCollectionPage,
  CBOShopifyPage,
  CBOShopifyPagePage,
} from '../../../../../core/domain/CBOShopifyPage';
import { countPublishedShoppablesFromMediaCollections } from '../../../../../utils/count-published-shoppables-from-media-collections';
import MediaCollectionPublishedPagesSelector from '../media-collections-editor/MediaCollectionPublishedPagesSelector';
import { createAddPublishedPagesToMediaCollectionDialogViewModel } from './AddPublishedPagesToMediaCollectionDialog.viewmodel';

const MediaCollectionPublishedPagesHeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  flex: 1,
  gap: theme.spacing(2),
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  position: 'relative',
}));

const CollectionLocationHeaderTitleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: '20px',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  paddingY: theme.spacing(1),
}));

const StyledDialogActions = styled(DialogActions)({
  padding: 0,
});

const DialogActionsContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  gap: theme.spacing(2),
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: '.95em',
}));

export const DISPLAYED_PRODUCT_PAGES_ROWS = 6;

const catalogProductToMediaCollectionPublishedPageProduct = (
  product: CBOCatalogProduct,
): MediaCollectionPublishedPageProduct => ({
  id: product.originalId,
  image: product.imageUrls[0] ?? null,
  title: product.title,
});

const shoppableVideoMediaToMediaCollectionPublishedPageProduct = (
  shoppableVideoMedia: CBOMediaCollectionMediaShoppable,
): MediaCollectionPublishedPageProduct => ({
  id: shoppableVideoMedia.productOriginalId,
  image: shoppableVideoMedia.productImage,
  title: shoppableVideoMedia.productTitle,
});

export type AddPublishedPagesToMediaCollectionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onDisplayUpdgradePlanDialog: () => void;
};

export type MediaCollectionPublishedPageProduct = {
  id: string;
  image: string | null;
  title: string;
};

export default function AddPublishedPagesToMediaCollectionDialog({
  isOpen,
  onClose,
  onDisplayUpdgradePlanDialog,
}: AddPublishedPagesToMediaCollectionDialogProps) {
  const { t } = useTranslation(['mediaCollections', 'common']);
  const viewModel = useSelector(createAddPublishedPagesToMediaCollectionDialogViewModel({ dispatch: useDispatch() }));
  const {
    canPublishOnHomePage,
    canPublishOnPagePages,
    currentMediaCollection,
    fetchOneMediaCollectionStatus,
    fetchProducts,
    maxPublishedShoppablesCount,
    mediaCollections,
    pages,
    productFetchingSearchTerm,
    products,
    productsFetchingStatus,
    resetUpdateMediaCollectionPages,
    tenantId,
    tenantName,
    trackUserJourneyEvent,
    updateMediaCollectionPages,
    updateMediaCollectionPagesStatus,
  } = viewModel;

  const [displayedProductPages, setDisplayedProductPages] = useState<MediaCollectionPublishedPageProduct[]>([]);
  const [selectedPages, setSelectedPages] = useState<CBOShopifyPage[]>([]);

  useEffect(() => {
    if (fetchOneMediaCollectionStatus === 'pending') {
      setDisplayedProductPages([]);
    } else {
      setDisplayedProductPages(getProductsToDisplay());
    }
  }, [currentMediaCollection?.id, productsFetchingStatus, fetchOneMediaCollectionStatus]);

  // TODO: Improve comparison
  const hasChanged =
    selectedPages.map(({ id }) => id).toString() !==
    currentMediaCollection?.publishedPages.map(({ id }) => id).toString();
  const submissionInProgress = updateMediaCollectionPagesStatus === 'pending';

  //TODO: Move usage to styled component
  const mdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (isOpen && fetchOneMediaCollectionStatus === 'loaded' && currentMediaCollection) {
      trackUserJourneyEvent({
        data: {
          'Playlist id': currentMediaCollection.id,
          'Playlist name': currentMediaCollection.title,
          'Tenant id': tenantId,
          'Tenant name': tenantName,
        },
        name: 'Set playlist destination',
      });
    }
  }, [isOpen, fetchOneMediaCollectionStatus]);

  useEffect(() => {
    setSelectedPages(currentMediaCollection?.publishedPages ?? []);
  }, [currentMediaCollection?.publishedPages]);

  useEffect(() => {
    if (updateMediaCollectionPagesStatus === 'success' || updateMediaCollectionPagesStatus === 'error') {
      resetUpdateMediaCollectionPages();
    }
  }, [updateMediaCollectionPagesStatus]);

  useEffect(() => {
    fetchProducts('');
  }, []);

  const getProductsToDisplay = (): MediaCollectionPublishedPageProduct[] => {
    if (!currentMediaCollection) {
      return [];
    }

    const catalogProductsPages: MediaCollectionPublishedPageProduct[] = products.map(
      catalogProductToMediaCollectionPublishedPageProduct,
    );

    const isSearchingProduct = productFetchingSearchTerm !== '';

    if (isSearchingProduct) {
      return catalogProductsPages;
    }

    const publishedProductPages: MediaCollectionPublishedPageProduct[] = currentMediaCollection.publishedPages.filter(
      isCBOMediaCollectionPublishedPageProduct,
    );

    // If the collection is already published on product pages, display them first
    if (publishedProductPages.length > 0) {
      const catalogProductsPagesExcludingPublished = catalogProductsPages.filter(({ id }) => {
        const isCatalogProductPagePublished = publishedProductPages.some(
          (publishedProductPage) => publishedProductPage.id === id,
        );
        return !isCatalogProductPagePublished;
      });

      return publishedProductPages
        .concat(catalogProductsPagesExcludingPublished)
        .slice(0, DISPLAYED_PRODUCT_PAGES_ROWS);
    }

    const uniqueById = (
      current: MediaCollectionPublishedPageProduct,
      index: number,
      allEntries: MediaCollectionPublishedPageProduct[],
    ) => {
      return index === allEntries.findIndex(({ id }) => id === current.id);
    };

    // Otherwise, if the collection has shoppable videos, display their products first
    const shoppableVideoProductsPages = currentMediaCollection.medias
      .filter(isCBOMediaCollectionMediaShoppable)
      .map(shoppableVideoMediaToMediaCollectionPublishedPageProduct)
      .filter(uniqueById)
      .sort((a, b) => a.title.localeCompare(b.title));

    if (shoppableVideoProductsPages.length === 0) {
      return catalogProductsPages;
    }

    const catalogProductsPagesExcludingShoppableProducts = catalogProductsPages.filter(
      (catalogProductPage) => !shoppableVideoProductsPages.some(({ id }) => id === catalogProductPage.id),
    );

    return shoppableVideoProductsPages
      .concat(catalogProductsPagesExcludingShoppableProducts)
      .slice(0, DISPLAYED_PRODUCT_PAGES_ROWS);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSearchProduct = (search: string) => {
    fetchProducts(search);
  };

  const handleSelectPages = (selectedPages: CBOShopifyPage[]) => {
    setSelectedPages(selectedPages);
  };

  const handleSubmit = () => {
    const checkIfHasExceededPublishedShoppablesCount =
      maxPublishedShoppablesCount && selectedPages.length > 0 && currentMediaCollection?.publishedPages.length === 0;

    if (checkIfHasExceededPublishedShoppablesCount) {
      const expectedPublishedShoppablesCount = getExpectedPublishedShoppablesCountAfterPublish(currentMediaCollection);

      if (expectedPublishedShoppablesCount > maxPublishedShoppablesCount) {
        onDisplayUpdgradePlanDialog();
        return;
      }
    }

    const payload = {
      ...currentMediaCollection,
      publishedPages: selectedPages,
    };

    updateMediaCollectionPages(payload);
  };

  const handleClear = () => {
    setSelectedPages([]);
  };

  const getExpectedPublishedShoppablesCountAfterPublish = (currentMediaCollection: CBOMediaCollection) => {
    const allMediaCollectionsWithPublishedCurrentMediaCollection = mediaCollections.map((mediaCollection) => {
      if (mediaCollection.id === currentMediaCollection.id) {
        return {
          ...currentMediaCollection,
          publishedPages: selectedPages,
        };
      }

      return mediaCollection;
    });

    return countPublishedShoppablesFromMediaCollections(allMediaCollectionsWithPublishedCurrentMediaCollection);
  };

  return (
    <StyledDialog
      PaperProps={{ sx: { borderRadius: '20px', maxWidth: mdScreen ? '80%' : '60%' } }}
      onClose={handleClose}
      open={isOpen}
    >
      <MediaCollectionPublishedPagesHeaderContainer>
        <CollectionLocationHeaderTitleContainer>
          <Box display="flex" gap={1}>
            <Typography lineHeight={1.1} variant="h6">
              {t('Destination')}
            </Typography>
            {selectedPages.length > 0 && (
              <Typography lineHeight={1.1} sx={{ color: 'primary' }} variant="h6">
                {selectedPages.length}
              </Typography>
            )}
          </Box>
          <Subtitle variant="subtitle1">{t('DestinationDescription')}</Subtitle>
        </CollectionLocationHeaderTitleContainer>
        <DialogActions sx={{ padding: 0 }}>
          <Button
            disabled={selectedPages.length === 0}
            onClick={handleClear}
            startIcon={<CloseIcon />}
            variant="contained"
          >
            {t('common:Clear')}
          </Button>
        </DialogActions>
      </MediaCollectionPublishedPagesHeaderContainer>
      <StyledDialogContent>
        <MediaCollectionPublishedPagesSelector
          canPublishOnHomePage={canPublishOnHomePage}
          canPublishOnPagePages={canPublishOnPagePages}
          // TODO: Use typeguard
          collectionPages={pages.filter(({ type }) => type === 'collection') as CBOShopifyCollectionPage[]}
          displayedProductPages={displayedProductPages}
          onSearchProduct={handleSearchProduct}
          onSelectPages={handleSelectPages}
          otherMediaCollections={mediaCollections.filter(({ id }) => id !== currentMediaCollection?.id)}
          pagePages={pages.filter(({ type }) => type === 'page') as CBOShopifyPagePage[]}
          productsFetchingStatus={productsFetchingStatus}
          selectedPages={selectedPages}
          tenantName={tenantName}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <DialogActionsContainer elevation={3}>
          <Button onClick={handleClose} variant="text">
            {t('common:Cancel')}
          </Button>
          <Button disabled={!hasChanged || submissionInProgress} onClick={handleSubmit} variant="contained">
            {t('common:Save')}
          </Button>
        </DialogActionsContainer>
      </StyledDialogActions>
    </StyledDialog>
  );
}
