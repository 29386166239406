import { Box, Switch, Typography, styled } from '@mui/material';
import React, { FC } from 'react';

const Container = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0.5),
}));

const Image = styled('img')({
  borderRadius: '100%',
  height: 50,
  objectFit: 'cover',
  width: 50,
});

type MediaCollectionPageRowProps = {
  additionalActions?: React.ReactNode;
  cannotPublishMessage?: string;
  image: string | null;
  isDisabled: boolean;
  isSelected: boolean;
  label: string;
  onChange?: (isSelected: boolean) => void;
};

const MediaCollectionPageRow: FC<MediaCollectionPageRowProps> = ({
  additionalActions,
  cannotPublishMessage,
  image,
  isDisabled,
  isSelected,
  label,
  onChange,
}) => {
  return (
    <Container>
      {/* TODO: Styled component */}
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flex: 1,
          gap: (theme) => theme.spacing(2),
        }}
      >
        {!!image && (
          <Box>
            <Image src={image} />
          </Box>
        )}
        <Typography fontWeight="bold">
          {label}
          {!!cannotPublishMessage && (
            <Typography
              fontWeight="bold"
              sx={{
                color: (theme) => theme.palette.secondary.main,
                fontSize: '0.65em',
              }}
            >
              {cannotPublishMessage}
            </Typography>
          )}
        </Typography>
      </Box>
      {!cannotPublishMessage && (
        <Box>
          <Switch checked={isSelected} disabled={isDisabled} onChange={() => onChange?.(!isSelected)} />
        </Box>
      )}
      {additionalActions}
    </Container>
  );
};

export default MediaCollectionPageRow;
